import React, { useState } from 'react';
import {
    IonPage, IonCard, IonCardHeader, IonCardTitle, IonButton,
    IonInput, IonRow, IonCardContent, IonCol, IonLabel,IonSpinner
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import {
      getvalidatepoAction,getpodetailsAction
} from '../receive-po-odoo/action';
import { AppAlert } from '../../components/app-alert';
import './ValidatePO.css';

const ValidatePO = (props) => {

    const [toggle, setToggle] = useState(false);
    const [poid, setPoid] = useState(false);
    const [odooid, setOdoo] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ show: false, msg: '' });
    const [showModal, setShowModal] = useState(false);
    const [addshowModal, setAddshowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to control button
    const [confirmValidate, setConfirmValidate] = useState(false); // State for confirmation alert
    const [storeName, setStoreName] = useState(''); // New state for store name
 





    const handleKeyDown = (event, x = '') => {
        event.preventDefault();
        if (x == '') {
            var paste_value = event.clipboardData ? event.clipboardData.getData("Text") : x;
        }
        else {
            var paste_value = x
        }
        if (paste_value) {
            if (!toggle) {       //if toggle is off
                props.getpodetails(paste_value).then((response) => {
                    if (response && !response[0].error) {
                        setPoid(paste_value)
                        setToggle(!toggle) // toggle on
                        const data = response[0].data;
                        setStoreName(data.store_name || 'Unknown Store')
                        // console.log("store name",data.store_name)
                        
                        if (data.order_lines.length < 1 || !data.partner_ref) {
                            setIsButtonDisabled(true);
                            let errorMessage = '';
                            if (!data.partner_ref) {
                                errorMessage += '❌  PO has No Vendor Invoice Number';
                            }
                            if (data.order_lines.length < 1) {
                                errorMessage += (errorMessage ? ' And ' : '') + 'No Items';
                            }

                            setAlertMessage({
                                show: true,
                                msg: errorMessage,
                            });
                        } else {
                            setIsButtonDisabled(false);
                        }
                    }
                    else {
                        setAlertMessage({ show: true, msg: 'No po found with id =  ' + paste_value });
                        setIsButtonDisabled(true);  //if ito id not found or it's wrong

                    }
                });
            }

            else {                 //if toggle is already switch on
                var result = props.barcodeScan[0].data.order_lines.find(item => item.barcode === paste_value);
                if (result) {
                    setOdoo(result.id)
                    setShowModal(true)


                }
                else {
                    setPoid(poid)
                }

            }
        }
    }


    const OnValidateClick = (poid) => {
        if (!poid) {
            setAlertMessage({ show: true, msg: 'PO ID is required to validate.' });
            return;
        }
        setIsLoading(true); 
        props.getvalidatepo(poid)
            .then(response => {
                setIsLoading(false);
                if (response.success) {
                    setAlertMessage({ 
                        show: true,
                        msg: 'PO successfully validated!',
                    });
                    setAddshowModal(() => {
                        setToggle(false); // Go back to the previous screen
                    });
                } else {
                    setAlertMessage({ 
                        show: true, 
                        msg: `❌ Validation failed: 
                            - Please ensure the PO has been confirmed.
                            - Check if it has already been received.`, });
                }
            })
            .catch(error => {
                setIsLoading(false);
                setAlertMessage({ 
                    show: true, 
                    msg: `❌ An unexpected error occurred while validating the PO. 
                            Please try again.`,
                });
            });
    };


    const barcodeitems = () => {
        if (props.barcodeScan) {
            return (
                props.barcodeScan[0].data.order_lines.map((value, index) => {
                    return (

                        <IonCard key={index} className='distribution-card1'>
                            <IonRow >
                                <IonCol sizeMd='6'>
                                    <IonLabel className='head-label-small'><strong>{value.name}</strong></IonLabel>
                                </IonCol>
                                <IonCol sizeMd='3'>
                                    <IonLabel className='head-label-small'><strong>{value.barcode}</strong></IonLabel>
                                </IonCol>
                                <IonCol sizeMd='3'>
                                    <IonLabel className='head-label-small'><strong>{value.qty_ordered}</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCard>
                    );
                })
            );
        }
    }

    const screen1 = () => {
        return (
            <div class='searchcriteria'>
                <h1>Scan PO  or  Enter PO/Invoice Ref Number</h1>
                <IonRow>
                <IonCol sizeSm='1'></IonCol>
                    <IonCol sizeSm='6' sizeXs='12'>
                        <IonLabel className='large-label'></IonLabel>
                        <IonInput
                            className="scaninput"
                            onIonChange={(e) => setPoid(e.detail.value)}
                        ></IonInput>
                    </IonCol>
                    <IonCol sizeSm="3" sizeXs="12">
                    <IonButton
                        className="search-button"
                        onClick={(e) => handleKeyDown(e, poid)}
                    >
                        Search
                    </IonButton>
                </IonCol>
                </IonRow>
            </div>
        );
    }

    const screen2 = () => {
        return (
            <IonCard className='distribution-card1'>
                <IonRow>
                    <IonCol sizeMd="3">
                        <IonButton className='button action-btn' type='button' onClick={() => { setToggle(!toggle) }}>
                            Back
                        </IonButton>
                    </IonCol>
                    <IonCol sizeMd="3">
                        <IonLabel className='center'><strong><h1>{poid}</h1></strong></IonLabel>
                    </IonCol>
                    <IonCol sizeMd="3">
                        <IonButton className='button action-btn' type='button' 
                        // onClick={() => OnValidateClick(poid)}
                        onClick={() => setConfirmValidate(true)} 
                        disabled={isLoading || isButtonDisabled}>
                           {isLoading ? <IonSpinner name="dots" /> : 'Receive'}
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonCard className='barcode-items'>
                    <div>
                        <IonCardHeader>
                            <IonCardTitle>
                                PO Items Receive List
                            </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent className='barcode-items'>
                            <IonRow>
                                <IonCol sizeMd='6' >
                                    <IonLabel className='head-label-small'><strong>Product Name</strong></IonLabel>
                                </IonCol>
                                <IonCol sizeMd='3'>
                                    <IonLabel className='head-label-small'><strong>Barcode</strong></IonLabel>
                                </IonCol>
                                <IonCol sizeMd='3'>
                                    <IonLabel className='head-label-small'><strong>Qty</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                            {barcodeitems()}
                        </IonCardContent>
                    </div>
                </IonCard>
            </IonCard>
        );
    }

    const getDom = () => {
        if (!toggle) {
            return screen1()

        }
        else {
            return screen2()
        }
    }


    return (
        <IonPage onPaste={handleKeyDown} contentEditable={false} >
            <AppHeader headerText='Validate PO Odoo' redirectTo={props.history.push} />
            <div class="pallet-body">
                {getDom()}
            </div>
            {/* Confirmation Alert */}
            <AppAlert
                showAlert={confirmValidate}
                headerText="Confirm Validation"
                message={`🏬 Are you sure you want to receive this PO for : ${storeName}`}
                btnCancelText="Cancel"
                cancelClick={() => setConfirmValidate(false)} // Close alert on cancel
                btnOkText="Confirm"
                okClick={() => {
                    setConfirmValidate(false); // Close alert
                    OnValidateClick(poid); // Proceed with validation
                }}
            />
            <AppAlert showAlert={alertMessage.show} headerText={alertMessage.headerText ? alertMessage.headerText : " "}
             message={alertMessage.msg} btnCancelText={alertMessage.btnCancelText ? alertMessage.btnCancelText : "Ok"} 
             cancelClick={() => { setAlertMessage({ show: false, msg: '' }) }}
                btnOkText={alertMessage.btnOkText ? alertMessage.btnOkText : false}
                // okClick={() => { setAddshowModal(true) }}
                okClick={() => {
                    setAlertMessage({ show: false, msg: '' });
                    if (addshowModal) {
                        addshowModal(); // Perform the action (navigate back in this case)
                    }
                }}  />
        </IonPage>
    );
};

const mapStateToProps = state => ({
    barcodeScan: state.receiveodoo.barcodeScan,
    userName: localStorage.getItem('userName')
});

const mapDispatchToProps = dispatch => ({
    getvalidatepo: (poId) => dispatch(getvalidatepoAction(poId)),
    getpodetails: (poId) => dispatch(getpodetailsAction(poId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValidatePO));
